import React, { Component } from 'react';
import logo from './../../logo.png';

import { Link } from 'react-router-dom';

import { signOut } from 'firebase/auth';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
    this.onLogout = this.onLogout.bind(this);
  }

  onLogout(event) {
    event.preventDefault();
    signOut(this.props.auth)
  }

  render() {
    const {isAuthenticated} = this.props;
    const {} = this.state;
    const pathname = window.location.pathname;

    return(
      <div style={{ marginTop: 24, height: 90, width: '100%', marginBottom: 24 }}>
        <div>
          <img src={logo} height={55} />
        </div>
        {isAuthenticated && (
          <div style={{marginTop: 14, display: 'flex', justifyContent: 'space-between', position: 'relative'}}>
            <div>
              <Link style={{marginRight: 20, paddingBottom: 4, borderBottom: pathname === '/news' ? "1px solid rgba(0,0,0,0.15)" : 'none'}} to="/news">News</Link>
              <Link style={{marginRight: 20, paddingBottom: 4, borderBottom: pathname === '/talents' ? "1px solid rgba(0,0,0,0.15)" : 'none'}} to="/talents">Talents</Link>
              <Link style={{marginRight: 20, paddingBottom: 4, borderBottom: pathname === '/packages' ? "1px solid rgba(0,0,0,0.15)" : 'none'}} to="/packages">Packages</Link>
            </div>
            <div>
              <a href="/" onClick={this.onLogout}>Logout</a>
            </div>
            <div style={{position: 'absolute', right: 0, bottom: -5, width: "30%", borderBottom: '1px solid rgba(0,0,0,0.15)'}} />
          </div>
        )}
      </div>
    );
  }
}

export default Header;
