import _ from 'lodash';
import React, { Component } from 'react';
import ReactImageFallback from "react-image-fallback";

class Image extends Component {
  render() {
    const { src, type } = this.props;
    let typedSrc = src;

    if (src) {
      if (!src.startsWith('blob')) {
        const legacyBucketName = 'nest-test-778d0.appspot.com';
        const legacyProcessedBucketName = 'nest-test-778d0-processed';
        const originalBucketName = 'nest-dea0a.appspot.com';
        const processedBucketName = 'nest-dea0a-processed';

        let bucketReplacedSrc = src.replace(originalBucketName, processedBucketName);
        bucketReplacedSrc = bucketReplacedSrc.replace(legacyBucketName, legacyProcessedBucketName);
        typedSrc = bucketReplacedSrc.replace('%2Fimage%2F', `%2Fimage%2F${type}%2F`);
      }

      const loader = <div style={{ height: '100%', width: 100 }} />

      let dims = {height: '100%'}
      switch(type) {
        case 'mini':
          dims = { width: 74, height: 110 }
          break;
        case 'thumb':
          dims = { width: 187, height: 280 }
          break;
        default:
          break;
      }

      return(
        <div style={{...dims, display: 'flex', justifyContent: 'center'}}>
          <ReactImageFallback
            {..._.omit(this.props, ['type', 'src'])}
            style={{height: dims.height}}
            src={typedSrc}
            fallbackImage={src}
            initialImage={loader}
            alt="img"
            />
        </div>
      );
    }
    return "";
  }
}

export default Image;
