import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMove } from 'react-sortable-hoc';

import { Table, Button } from 'reactstrap';
import AddIcon from '../../components/AddIcon';
import Image from '../../components/Image';
import EditModal from './EditModal';
import { loadDB } from '../../App';
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      lastItem: null,
      pageSize: 6,
      totalCount: 0,
      currentItem: null,
      orderBy: { path: 'sortNumber', direction: 'desc' },
    };

    this.fetchPage = this.fetchPage.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  onSortEnd({ oldIndex, newIndex }) {
    const { items, db } = this.state;
    if (db) {
      const orderedItems = arrayMove(items, oldIndex, newIndex);
      const maxIndex = _.max([oldIndex, newIndex]);
      const lowestSortNumber = items[maxIndex].sortNumber;
      const sortedItems = _.map(
        orderedItems.slice(0, maxIndex + 1).reverse(),
        (item, index) => {
          return { ...item, sortNumber: lowestSortNumber + index };
        }
      );
      console.log(_.map(sortedItems, 'sortNumber'));
      this.setState({ items: orderedItems }, () => {
        Promise.all(
          _.map(sortedItems, (item) => {
            const ref = doc(collection(db, 'news'), item.id);
            return updateDoc(ref, { sortNumber: item.sortNumber });
          })
        )
          .then(() => {
            return this.fetchCurrentPage();
          })
          .catch((error) => console.log(error));
      });
    }
  }

  async componentDidMount() {
    try {
      const { db } = await loadDB();
      const storage = getStorage();
      const ref = collection(db, 'news');
      const { path, direction } = this.state.orderBy;
      const q = query(
        ref,
        orderBy(path, direction),
        limit(this.state.pageSize)
      );
      const resp = await getDocs(q);
      const items = this.state.items;
      resp.forEach((doc) => {
        items.push({ id: doc.id, ...doc.data() });
      });
      const lastItem =
        resp.docs.length < this.state.pageSize
          ? null
          : resp.docs[resp.docs.length - 1];
      this.setState({ items, lastItem, db, storage });
    } catch (error) {
      console.log('Error getting documents: ', error);
    }
  }

  async delete(id) {
    const { db } = this.state;
    if (db) {
      try {
        const { db } = await loadDB();
        const ref = doc(collection(db, 'news'), id);
        await deleteDoc(ref);
        this.closeModal();
        this.fetchCurrentPage();
      } catch (error) {
        console.log('Error deleteing document: ', id);
      }
    }
  }

  async save(form) {
    form.updatedAt = Date.now();
    _.get(form, 'cover.width') && (form.cover.width = Number(form.cover.width));
    _.get(form, 'cover.height') &&
      (form.cover.height = Number(form.cover.height));
    const { db } = this.state;
    if (db) {
      try {
        const findPermalink = async (permalink) => {
          const ref = collection(db, 'news');
          const q = query(ref, where('permalink', '==', permalink));
          const resp = await getDocs(q);
          if (resp.empty) {
            return permalink;
          } else {
            const counter =
              (Number(permalink.split('-').reverse()[0]) || 0) + 1;
            return findPermalink(`${permalink}-${counter}`);
          }
        };
        const updateRecord = async () => {
          const ref = doc(collection(db, 'news'), form.id);
          await setDoc(ref, form, { merge: true });
          return this.fetchCurrentPage();
        };
        const getLatestSortNumber = async () => {
          const ref = collection(db, 'news');
          const q = query(ref, orderBy('sortNumber', 'desc'), limit(1));
          const resp = await getDocs(q);
          let sortNumber = 0;
          resp.forEach((doc) => {
            sortNumber = doc.data().sortNumber;
          });
          return sortNumber;
        };
        if (!form.permalink) {
          form.createdAt = form.updatedAt;
          let permalink = _.kebabCase(form.title);
          return findPermalink(permalink).then((permalink) => {
            form.permalink = permalink;
            return getLatestSortNumber().then((latestSortNumber) => {
              form.sortNumber = latestSortNumber + 1;
              return updateRecord();
            });
          });
        } else {
          return updateRecord();
        }
      } catch (error) {
        console.log('Error saving document: ', form.id);
      }
    }
  }

  closeModal() {
    this.setState({ currentItem: null });
  }

  async openModal(item) {
    const { db } = this.state;
    if (db) {
      const newRecord = !item.id;
      if (newRecord) {
        const docRef = doc(collection(db, 'news'));
        item.id = docRef.id;
      }
      this.setState({ currentItem: item, newRecord });
    }
  }

  async fetchCurrentPage() {
    const { db } = this.state;
    const { path, direction } = this.state.orderBy;
    if (db) {
      try {
        const ref = collection(db, 'news');
        const q = query(
          ref,
          orderBy(path, direction),
          limit(this.state.pageSize)
        );
        const resp = await getDocs(q);
        const items = [];
        resp.forEach((doc) => {
          items.push({ id: doc.id, ...doc.data() });
        });
        const lastItem =
          resp.docs.length < this.state.pageSize
            ? null
            : resp.docs[resp.docs.length - 1];
        this.setState({ items, lastItem });
      } catch (error) {
        console.log('Error getting documents: ', error);
      }
    }
  }

  async fetchPage() {
    const { db } = this.state;
    const { path, direction } = this.state.orderBy;
    if (db) {
      try {
        const ref = collection(db, 'news');
        const q = query(
          ref,
          orderBy(path, direction),
          startAfter(this.state.lastItem),
          limit(this.state.pageSize)
        );
        const resp = await getDocs(q);
        const items = this.state.items;
        resp.forEach((doc) => {
          items.push({ id: doc.id, ...doc.data() });
        });
        const lastItem =
          resp.docs.length < this.state.pageSize
            ? null
            : resp.docs[resp.docs.length - 1];
        this.setState({ items, lastItem });
      } catch (error) {
        console.log('Error getting documents: ', error);
      }
    }
  }

  render() {
    const { items, db } = this.state;

    const SortableItem = SortableElement(({ item }) => {
      const preview =
        _.get(item, 'cover.mimeType') === 'video/embed' ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 74,
              height: 110,
              backgroundColor: '#e3e3e3',
              color: '#939393',
            }}
          >
            video
          </div>
        ) : (
          <Image src={_.get(item, 'cover.src', '')} type='mini' />
        );
      return (
        <tr
          onClick={() => this.openModal(item)}
          style={{ cursor: 'pointer' }}
        >
          <td>{preview}</td>
          <td>{item.title}</td>
          <td>{item.permalink}</td>
          <td>{moment(item.createdAt).format('ll')}</td>
          <td>{item.published ? 'Yes' : '-'}</td>
          <td>{item.sortNumber}</td>
          <td>{item.id}</td>
          <td>
            <a
              rel='noopener noreferrer nofollow'
              target='_blank'
              href={`https://nestmodelmanagement.com/news/${item.permalink}`}
            >
              view
            </a>
          </td>
        </tr>
      );
    });

    const SortableList = SortableContainer(({ items }) => {
      return (
        <tbody>
          {items.map((item, index) => (
            <SortableItem
              key={`sortable-${item.id}`}
              index={index}
              item={item}
            />
          ))}
        </tbody>
      );
    });

    return (
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          marginTop: 24,
        }}
      >
        <div style={{ flex: 1, overflowY: 'scroll', position: 'relative' }}>
          <Table>
            <thead>
              <tr>
                <th>/</th>
                <th>Title</th>
                <th>Permalink</th>
                <th>Created at</th>
                <th>Published</th>
                <th>Sortnumber</th>
                <th>#</th>
                <th />
              </tr>
            </thead>
            <SortableList
              items={items}
              onSortEnd={this.onSortEnd}
              distance={5}
            />
          </Table>
          {this.state.lastItem !== null && (
            <div style={{ margin: 34, textAlign: 'center' }}>
              <Button onClick={this.fetchPage}>Load more</Button>
            </div>
          )}
        </div>
        <div
          style={{
            boxShadow: '0px -1px 0px 0px rgba(0,0,0,0.15)',
            position: 'relative',
          }}
        >
          <div
            style={{ position: 'absolute', top: -120, right: 20, zIndex: 1 }}
          >
            <AddIcon onClick={() => this.openModal({})} />
          </div>
        </div>
        {this.state.currentItem && (
          <EditModal
            save={this.save}
            delete={this.delete}
            currentItem={this.state.currentItem}
            newRecord={this.state.newRecord}
            closeModal={this.closeModal}
            db={db}
            storage={this.state.storage}
          />
        )}
      </div>
    );
  }
}

export default News;
