import React, { Component } from 'react';

class AddIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    const { onClick } = this.props;
    const {} = this.state;

    return(
      <div
        onClick={onClick}
        style={{
          backgroundColor: '#d0d0d0',
          borderRadius: 40,
          width: 80,
          height: 80,
          position: 'relative',
          cursor: 'pointer'
        }}
        >
        <div style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <div style={{width: 25, height: 2, backgroundColor: 'black'}}></div>
        </div>
        <div style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <div style={{width: 2, height: 25, backgroundColor: 'black'}}></div>
        </div>
      </div>
    );
  }
}

export default AddIcon;
