import _, { keyBy, map } from 'lodash';
import React, { Component } from 'react';

import DropZone from '../../../components/DropZone';
import classNames from 'classnames';
import { arrayMove } from 'react-sortable-hoc';
import SortableList from '../../../components/SortableList';
import MediaItem from '../../../components/MediaItem';
import EmbedVideoModal from '../../Modals/EmbedVideoModal';

import { generateRandom, capitalizeArtistName } from '../../../utils';

import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  TabContent,
  TabPane,
  Spinner,
} from 'reactstrap';
import { loadDB } from '../../../App';
import {
  collection,
  getDocs,
  query,
  orderBy,
  startAt,
  endAt,
} from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

class EditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'details',
      loading: false,
      videoModalOpen: false,
      showVideoModal: null,
      modelLinkModalOpen: false,
      results: {},
      searchString: '',
      form: {
        title: '',
        description: '',
        credits: '',
        published: false,
        cover: '',
        media: [],
        ...props.currentItem,
      },
    };

    this.closeModal = this.closeModal.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onChange = this.onChange.bind(this);
    this.save = this.save.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.clearFiles = this.clearFiles.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.delete = this.delete.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.deleteMedia = this.deleteMedia.bind(this);
    this.searchModel = this.searchModel.bind(this);
  }

  async componentDidMount() {
    const { db } = await loadDB();
    await this.setState({ db });
  }

  async fetchDocs(q) {
    const docs = await getDocs(q);
    const items = keyBy(map(docs.docs, (doc) => doc.data()), 'id');
    const lastItem =
      docs.size < this.state.pageSize ? null : docs.docs[docs.size - 1];
    return { items, lastItem };
  }

  searchModel = async () => {
    try {
      const { db, searchString } = this.state;
      if (db) {
        const ref = collection(db, 'talents');
        const q = query(
          ref,
          orderBy('artistName'),
          startAt(searchString),
          endAt(searchString + '\uf8ff')
        );
        const { items } = await this.fetchDocs(q);
        console.log('ITEMS: ', items);
        await this.setState({ results: items });
      }
    } catch (error) {
      console.log('Error getting documents: ', error);
    }
  };

  onSortEnd = ({ oldIndex, newIndex, type }) => {
    this.setState(({ form }) => ({
      form: { ...form, [type]: arrayMove(form[type], oldIndex, newIndex) },
    }));
  };

  delete() {
    const resp = window.confirm('Do you really want to delete?');
    if (resp) {
      this.props.delete(this.state.form.id);
    }
  }

  onDrop(uploads, rejectedFiles, folder) {
    if (!_.isEmpty(rejectedFiles)) {
      alert(
        [
          'following files were too big, please upload only max 8 MB files',
          _.map(rejectedFiles, (file) => file.name).join(', '),
        ].join(': ')
      );
    }
    const filesPromises = _.map(uploads, (file) => {
      var img = document.createElement('img');
      const src = URL.createObjectURL(file);
      img.src = src;
      return new Promise((resolve, reject) => {
        img.onload = () => {
          resolve({
            src,
            file,
            mimeType: file.type,
            width: img.width,
            height: img.height,
          });
        };
        img.onerror = (error) => {
          reject(error);
        };
      });
    });
    Promise.all(filesPromises).then((files) => {
      const newFiles =
        folder === 'cover'
          ? { cover: files[0] }
          : { [folder]: [...files, ...this.state.form[folder]] };
      this.setState({
        form: {
          ...this.state.form,
          ...newFiles,
        },
      });
    });
  }

  clearFiles() {
    _.each(
      this.state.form.media,
      (file) => file.file && URL.revokeObjectURL(file.url)
    );
  }

  componentWillUnmount() {
    this.clearFiles();
  }

  closeModal() {
    this.clearFiles();
    this.setState(
      {
        activeTab: 'details',
      },
      () => {
        this.props.closeModal();
      }
    );
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  async uploadFile(item, folder) {
    const storage = getStorage();
    if (item && item.file) {
      const type = String(item.mimeType.match(/image|video/));
      if (type) {
        const file = item.file;
        const metadata = {
          contentType: item.mimeType,
        };
        const path = `news/${
          this.state.form.id
        }/${folder}/${type}/${generateRandom(file.name)}`;
        try {
          const uploadRef = ref(storage, path);
          await uploadBytes(uploadRef, file, metadata);
          const downloadURL = await getDownloadURL(uploadRef);
          return _.omit({ ...item, src: downloadURL }, 'file');
        } catch (error) {
          console.log(error);
        } finally {
          URL.revokeObjectURL(file.url);
        }
      }
    }
    return item;
  }

  async save() {
    const { form } = this.state;
    this.setState({ loading: true }, () => {
      Promise.all(_.map(form.media, (item) => this.uploadFile(item, 'media')))
        .then((resp) => {
          form.media = resp;
          return this.uploadFile(form.cover, 'cover');
        })
        .then((resp) => {
          form.cover = resp;
          return this.props.save(form);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          return this.setState({ loading: false });
        });
    });
  }

  onChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({ form: { ...this.state.form, [name]: value } });
  }

  deleteMedia(item, folder) {
    const { form } = this.state;
    _.remove(form[folder], (formItem) => formItem.src === item.src);
    this.setState({ form }, () => {
      this.save();
    });
  }

  render() {
    const { form, activeTab } = this.state;
    const openVideoModal = (gallery) =>
      this.setState({ videoModalOpen: gallery });

    const renderResult = (result, field = 'description') => {
      const name = result.artistName;
      const link = ` <a href="https://nestmodelmanagement.com/${
        result.permalink
      }">${capitalizeArtistName(name)}</a> `;
      return (
        <div style={{ verticalAlign: 'top' }} key={`book-link-${result.id}`}>
          {name}
          <Button
            color='link'
            onClick={() =>
              this.setState({
                form: {
                  ...this.state.form,
                  description: this.state.form.description + link,
                },
                searchString: '',
                results: {},
                modelLinkModalOpen: false,
              })
            }
          >
            add link
          </Button>
        </div>
      );
    };

    return (
      form && (
        <Modal
          isOpen={!!form}
          toggle={this.closeModal}
          className={this.props.className}
          style={{ padding: 24, maxWidth: 1440 }}
        >
          <ModalHeader toggle={this.closeModal}>
            {this.state.form.title || 'Create story'}
          </ModalHeader>
          <ModalBody style={{ position: 'relative' }}>
            {this.state.loading && (
              <div
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.55)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Spinner />
              </div>
            )}
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classNames({ active: activeTab === 'details' })}
                  onClick={() => {
                    this.toggle('details');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classNames({ active: activeTab === 'media' })}
                  onClick={() => {
                    this.toggle('media');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Media
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent style={{ marginTop: 20 }} activeTab={activeTab}>
              <TabPane tabId='details'>
                <Form>
                  <Row style={{ alignItems: 'flex-end' }}>
                    <Col>
                      <FormGroup>
                        <Label for='title'>Title</Label>
                        <Input
                          type='text'
                          name='title'
                          id='title'
                          placeholder='Title'
                          onChange={this.onChange}
                          value={form.title}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{ marginBottom: '1rem' }}>
                      <FormGroup check>
                        <Label check>
                          <Input
                            name='published'
                            type='checkbox'
                            onChange={this.onChange}
                            checked={form.published}
                          />{' '}
                          Published
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='description'>
                          Description
                          <Button
                            size='sm'
                            color='link'
                            style={{
                              marginLeft: 10,
                              padding: 0,
                              verticalAlign: 'top',
                            }}
                            onClick={() => {
                              this.setState({
                                form: {
                                  ...this.state.form,
                                  description:
                                    this.state.form.description +
                                    ' <a href="URL" target="_blank" rel="nofollow noopener">LABEL</a> ',
                                },
                              });
                            }}
                          >
                            add link
                          </Button>
                          <Button
                            size='sm'
                            color='link'
                            style={{
                              marginLeft: 10,
                              padding: 0,
                              verticalAlign: 'top',
                            }}
                            onClick={() => {
                              this.setState({ modelLinkModalOpen: true });
                            }}
                          >
                            add model book
                          </Button>
                        </Label>
                        <Input
                          type='textarea'
                          name='description'
                          id='description'
                          placeholder='description'
                          onChange={this.onChange}
                          value={form.description}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='credits'>
                          Credits
                          <Button
                            size='sm'
                            color='link'
                            style={{
                              marginLeft: 10,
                              padding: 0,
                              verticalAlign: 'top',
                            }}
                            onClick={() => {
                              this.setState({
                                form: {
                                  ...this.state.form,
                                  credits:
                                    this.state.form.credits +
                                    ' <a href="URL" target="_blank" rel="nofollow noopener">LABEL</a> ',
                                },
                              });
                            }}
                          >
                            add link
                          </Button>
                        </Label>
                        <Input
                          type='textarea'
                          name='credits'
                          id='credits'
                          placeholder='credits'
                          onChange={this.onChange}
                          value={form.credits}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </TabPane>
              <TabPane tabId='media'>
                <legend>Cover</legend>
                <Form
                  style={{
                    marginLeft: -5,
                    marginTop: -5,
                  }}
                >
                  <DropZone
                    onDrop={(acceptedFiles, rejectedFiles) =>
                      this.onDrop(acceptedFiles, rejectedFiles, 'cover')
                    }
                    onAddVideo={() => openVideoModal('cover')}
                    multiple={false}
                  />
                  <div
                    style={{
                      display: form.cover.src ? 'flex' : 'none',
                      alignItems: 'flex-end',
                    }}
                  >
                    <div style={{ margin: 5 }}>
                      <div
                        style={{
                          height: 340,
                        }}
                      >
                        <div
                          style={{
                            height: '100%',
                          }}
                        >
                          {form.cover && (
                            <MediaItem
                              showDelete={false}
                              src={form.cover.src}
                              folder='cover'
                              item={form.cover}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
                <legend>Images and videos</legend>
                <Form
                  style={{
                    marginLeft: -5,
                    marginTop: -5,
                  }}
                >
                  <DropZone
                    onDrop={(acceptedFiles, rejectedFiles) =>
                      this.onDrop(acceptedFiles, rejectedFiles, 'media')
                    }
                    onAddVideo={() => openVideoModal('media')}
                  />
                  <SortableList
                    items={form.media}
                    folder='media'
                    deleteMethod={this.deleteMedia}
                    axis='xy'
                    helperClass='is-dragging'
                    onSortEnd={({ oldIndex, newIndex }) =>
                      this.onSortEnd({ oldIndex, newIndex, type: 'media' })
                    }
                    shouldCancelStart={(e) => !e.target.draggable}
                  />
                </Form>
              </TabPane>
            </TabContent>
          </ModalBody>
          <ModalFooter
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              {!this.props.newRecord && (
                <Button
                  color='danger'
                  onClick={this.delete}
                  disabled={this.state.loading}
                >
                  Delete
                </Button>
              )}
            </div>
            <div>
              <Button
                color='primary'
                onClick={this.save}
                disabled={this.state.loading}
              >
                Save
              </Button>{' '}
              <Button
                color='secondary'
                onClick={this.closeModal}
                disabled={this.state.loading}
              >
                Close
              </Button>
            </div>
          </ModalFooter>
          {this.state.videoModalOpen && (
            <EmbedVideoModal
              closeVideoModal={() => this.setState({ videoModalOpen: false })}
              addVideo={(props) => {
                this.setState(
                  {
                    form: {
                      ...this.state.form,
                      [this.state.videoModalOpen]:
                        this.state.videoModalOpen === 'cover'
                          ? {
                              mimeType: 'video/embed',
                              src: props.url,
                              width: props.width,
                              height: props.height,
                            }
                          : [
                              {
                                mimeType: 'video/embed',
                                src: props.url,
                                width: props.width,
                                height: props.height,
                              },
                              ...this.state.form[this.state.videoModalOpen],
                            ],
                    },
                  },
                  () => {
                    this.setState({ videoModalOpen: false });
                  }
                );
              }}
            />
          )}
          {this.state.modelLinkModalOpen && (
            <Modal
              isOpen={this.state.modelLinkModalOpen}
              toggle={() =>
                this.setState({
                  modelLinkModalOpen: false,
                  searchString: '',
                })
              }
            >
              <ModalHeader>Choose model</ModalHeader>
              <ModalBody>
                <Form inline>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'stretch',
                    }}
                  >
                    <Input
                      style={{ flex: 1 }}
                      type='text'
                      id='search-model'
                      onChange={(e) =>
                        this.setState({ searchString: e.target.value })
                      }
                    />
                    <Button
                      onClick={this.searchModel}
                      style={{ marginLeft: 10 }}
                    >
                      Search Model
                    </Button>
                  </div>
                  <div>{_.map(_.values(this.state.results), renderResult)}</div>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color='secondary'
                  onClick={() =>
                    this.setState({
                      modelLinkModalOpen: false,
                      searchString: '',
                    })
                  }
                >
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </Modal>
      )
    );
  }
}

export default EditModal;
