import React, { Component } from 'react';

import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from 'reactstrap';


class CreatePackageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      book: true,      
      polaroids: false,      
      selection: props.selection,
      name: '',
      state: 'new',
    };
    
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({...this.state, [name]: value});
  }

  render() {
    return(
      <div>
        <Modal isOpen={true} toggle={this.props.closeModal} className={this.props.className}>
          <ModalHeader toggle={this.props.closeModal}>{this.state.name || "New package"}</ModalHeader>
          <ModalBody>
            <Form>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="name">Name</Label>
                    <Input type="text" name="name" id="name" placeholder="Package name" onChange={this.onChange} value={this.state.name} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input name="book" type="checkbox" onChange={this.onChange} checked={this.state.book} />{' '}
                      Book
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input name="polaroids" type="checkbox" onChange={this.onChange} checked={this.state.polaroids} />{' '}
                      Polaroids
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={(!this.state.polaroids && !this.state.book) || this.state.name.length < 3}
              color="primary"
              onClick={() => this.props.createPackage({ ...this.state })}>
                Save
              </Button>{' '}
            <Button color="secondary" onClick={this.props.closeModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default CreatePackageModal;
