import _ from 'lodash';
import React, { Component } from 'react';

import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from 'reactstrap';
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
} from 'firebase/firestore';
import { loadDB } from '../../App';

class AddToPackageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: props.selection,
      pageSize: 25,
      items: {},
      lastItem: undefined,
      packageId: undefined,
      orderBy: { path: 'createdAt', direction: 'desc' },
    };

    this.onChange = this.onChange.bind(this);
    this.onLoadMorePackages = this.onLoadMorePackages.bind(this);
  }

  async componentDidMount() {
    try {
      const { db } = await loadDB();
      await this.setState({ db });
      const ref = collection(db, 'packages');
      const { path, direction } = this.state.orderBy;
      const q = query(
        ref,
        orderBy(path, direction),
        limit(this.state.pageSize)
      );
      const resp = await getDocs(q);
      const items = this.state.items;
      resp.forEach((doc) => {
        items[doc.id] = { id: doc.id, ...doc.data() };
      });
      const lastItem =
        resp.docs.length < this.state.pageSize
          ? null
          : resp.docs[resp.docs.length - 1];
      this.setState({ items, lastItem, packageId: _.keys(items)[0] });
    } catch (error) {
      console.log('Error getting documents: ', error);
    }
  }

  async onLoadMorePackages() {
    if (this.state.lastItem !== null) {
      try {
        const { db } = this.state;
        const { path, direction } = this.state.orderBy;
        const ref = collection(db, 'packages');
        const q = query(
          ref,
          orderBy(path, direction),
          startAfter(this.state.lastItem),
          limit(this.state.pageSize)
        );
        const querySnapshot = await getDocs(q);
        const items = this.state.items;
        querySnapshot.forEach((doc) => {
          items[doc.id] = { id: doc.id, ...doc.data() };
        });
        const lastItem =
          querySnapshot.docs.length < this.state.pageSize
            ? null
            : querySnapshot.docs[querySnapshot.docs.length - 1];
        this.setState({ items, lastItem });
      } catch (error) {
        console.log('Error getting documents: ', error);
      }
    }
  }

  onChange(val) {
    this.setState({ ...this.state, packageId: val });
  }

  render() {
    const options = _.map(_.values(this.state.items), (item) => {
      return (
        <option key={`opt-${item.id}`} value={item.id}>
          {item.name}
        </option>
      );
    });
    options.push(
      <option key='opt-load-more' value='load-more'>
        Load more
      </option>
    );
    return (
      <div>
        <Modal
          isOpen={true}
          toggle={this.props.closeModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.props.closeModal}>
            {this.state.name || 'New package'}
          </ModalHeader>
          <ModalBody>
            <Form>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for='packageId'>Package</Label>
                    <Input
                      type='select'
                      name='packageId'
                      id='packageId'
                      placeholder='Select package'
                      onChange={(e) => {
                        const val = e.target.value;
                        return val === 'load-more'
                          ? this.onLoadMorePackages()
                          : this.onChange(val);
                      }}
                      value={this.state.packageId}
                    >
                      {options}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={!this.state.selection || !this.state.packageId}
              color='primary'
              onClick={() => this.props.addToPackage({ ...this.state })}
            >
              Save
            </Button>{' '}
            <Button color='secondary' onClick={this.props.closeModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default AddToPackageModal;
