import React, { Component } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import MediaItem from "../MediaItem";

class SortableList extends Component {
  render() {
    const SortableTag = SortableContainer(({ items, folder }) => {
      const SortableItem = SortableElement(({ value }) => {
        return (
          <li style={{listStyle: "none", display: "inline-block"}}>
            <MediaItem
              deleteMethod={this.props.deleteMethod}
              item={value}
              folder={folder}
              style={{ cursor: "move" }}
            />
          </li>
        );
      });

      return (
        <ul style={{ listStyle: "none" }}>
          {items.map((value, index) => (
            <SortableItem key={`item-${index}`} index={index} value={value} />
          ))}
        </ul>
      );
    });

    return <SortableTag {...this.props} />;
  }
}

export default SortableList;
