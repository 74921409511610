import _ from 'lodash'
import React from 'react'
import Dropzone from 'react-dropzone'
import classNames from 'classnames'

export default (props) => {
  const media = props.accept || ['image/*', 'video/*']
  const withVideo = _.includes(media, 'video/*')
  return(
    <div style={{display: 'flex'}}>
      <Dropzone
        onDrop={(acceptedFiles, rejectedFiles) => props.onDrop(acceptedFiles, rejectedFiles)}
        maxSize={props.maxSize || 8000000}
        accept={'image/*'}
        multiple={props.multiple !== false}
        >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div
              key='img'
              {...getRootProps({
                style: {
                  backgroundColor: '#e3e3e3',
                  borderRadius: 5,
                  width: 200,
                  height: 200,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  margin: 5,
                }
              })}
              className={classNames('dropzone', {'dropzone--isActive': isDragActive})}
            >
              <input {...getInputProps()} />
              <div style={{
                border: '1px solid gray',
                borderRadius: 3,
                padding: 8,
                margin: 14,
              }}>Browse</div>
              <div style={{
              }}>Or drop image here</div>
            </div>
          )
        }}
      </Dropzone>
      {
        withVideo && (
        <div
          key='video'
          onClick={props.onAddVideo}
          style={{
            backgroundColor: '#e3e3e3',
            borderRadius: 5,
            width: 200,
            height: 200,
            display: "flex",
            flexDirection: "column",
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            margin: 5,
          }}
        >
          <div style={{
            border: '1px solid gray',
            borderRadius: 3,
            padding: 8,
            margin: 14,
          }}>
            Embed Video
          </div>
        </div>)
      }
    </div>
  )
}
