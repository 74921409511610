import _ from "lodash";
import React, { Component } from "react";

import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col
} from "reactstrap";

import Image from "../../../components/Image";

class EditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      name: props.name,
      book: props.book,
      polaroids: props.polaroids,
      state: props.state,
      items: props.items,
      createdAt: props.createdAt,
      selection: props.selection,
      updatedAt: props.updatedAt
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({ ...this.state, [name]: value });
  }

  render() {
    const renderItem = item => {
      return (
        <div key={`item-${item.id}`} style={{ margin: 5, position: "relative" }}>
          <Image src={_.get(item, "thumbnail.src")} type="mini" />
          <div
            style={{
              position: "absolute",
              top: 5,
              right: 5,
              width: 20,
              height: 20,
              backgroundColor: "lightgray",
              borderRadius: 15,
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
            onClick={() =>
              this.setState({
                items: _.remove(this.state.items, aitem => aitem.id !== item.id),
                selection: _.remove(this.state.selection, id => id !== item.id)
              })
            }
          >
            x
          </div>
        </div>
      );
    };
    return (
      <div>
        <Modal
          isOpen={true}
          toggle={this.props.closeModal}
          className={this.props.className}
          style={{ padding: 24, maxWidth: 1440 }}
        >
          <ModalHeader toggle={this.props.closeModal}>
            {this.state.name}
          </ModalHeader>
          <ModalBody>
            <Form>
              <legend>Details</legend>
              <legend>
                https://nestmodelmanagement.com/package/{this.props.id}
              </legend>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="name">Name</Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Package name"
                      onChange={this.onChange}
                      value={this.state.name}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input
                        name="book"
                        type="checkbox"
                        onChange={this.onChange}
                        checked={this.state.book}
                      />{" "}
                      Book
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input
                        name="polaroids"
                        type="checkbox"
                        onChange={this.onChange}
                        checked={this.state.polaroids}
                      />{" "}
                      Polaroids
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="state">State</Label>
                    <Input
                      type="select"
                      name="state"
                      id="state"
                      placeholder="Package state"
                      onChange={this.onChange}
                      value={this.state.state}
                    >
                      <option>new</option>
                      <option>sent</option>
                      <option>opened</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <legend style={{ marginTop: 24 }}>Talents</legend>
              <Row>
                <Col>
                  <div
                    style={{
                      display: "flex",
                      marginLeft: -5,
                      marginRight: -5,
                      overflowX: "scroll"
                    }}
                  >
                    {_.map(this.state.items, renderItem)}
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between"
              }}
            >
              <div>
                <Button
                  color="danger"
                  onClick={() => this.props.delete(this.state.id)}
                >
                  Delete
                </Button>
              </div>
              <div>
                <Button
                  disabled={
                    !this.state.polaroids
                      ? !this.state.book
                      : this.state.name.length < 3
                  }
                  color="primary"
                  onClick={() => this.props.save({ ...this.state })}
                >
                  Save
                </Button>{" "}
                <Button color="secondary" onClick={this.props.closeModal}>
                  Close
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default EditModal;
