import React, { Component } from 'react';

class AddIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    const { onClick, style = {} } = this.props;
    const {} = this.state;

    return(
      <div
        className="hoverVisible"
        onClick={onClick}
        style={{
          ...style,
          cursor: 'pointer',
          backgroundColor: '#d0d0d0',
          opacity: 0.7,
          borderRadius: 10,
          width: 20,
          height: 20,
          position: 'relative',
          transform: 'rotate(45deg)',
          zIndex: 1,
        }}
        >
        <div style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <div style={{width: 15, height: 1, backgroundColor: 'black'}}></div>
        </div>
        <div style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <div style={{width: 1, height: 15, backgroundColor: 'black'}}></div>
        </div>
      </div>
    );
  }
}

export default AddIcon;
