import _ from 'lodash'
import React from 'react'
import ReactHtmlParser from 'react-html-parser'

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
} from 'reactstrap'

class EmbedVideoModal extends React.Component {
  state = {
    isOpen: true,
    value: '',
  }

  render() {
    const iframe = ReactHtmlParser(this.state.value)[0]
    const width = _.get(iframe, 'props.width')
    const height = _.get(iframe, 'props.height')
    const disabled = !(iframe && height && width)

    return (
      <Modal isOpen={!!this.state.isOpen} toggle={this.props.closeVideoModal} style={{padding: 24}}>
        <ModalHeader toggle={this.props.closeVideoModal}>Paste your vimeo embedding code here (make sure to paste the embed code, not the url)</ModalHeader>
        <ModalBody style={{position: 'relative'}}>
          <Input type='textarea' onChange={(e) => this.setState({value: e.target.value})} value={this.state.value} />
        </ModalBody>
        <ModalFooter style={{display: 'flex', justifyContent: 'flex-end'}}>
          <div>
            <Button color="primary" disabled={disabled} onClick={() => this.props.addVideo({url: this.state.value.match(/<iframe.*<\/iframe>/g)[0], width, height })}>Save</Button>{' '}
            <Button color="secondary" onClick={this.props.closeVideoModal}>Close</Button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

export default EmbedVideoModal;
