import { signInWithEmailAndPassword } from 'firebase/auth';
import React, { Component } from 'react';

import { Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };

    this.onChange = this.onChange.bind(this);
    this.onLogin = this.onLogin.bind(this);
  }

  onChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  onLogin(event) {
    event.preventDefault();
    const { email, password } = this.state;
    signInWithEmailAndPassword(this.props.auth, email, password).catch(function(
      error
    ) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log(errorCode, errorMessage);
    });
  }

  render() {
    return (
      <Form>
        <Row>
          <Col sm='12' md={{ size: 6, offset: 3 }}>
            <FormGroup>
              <Label for='email'>Email</Label>
              <Input
                type='email'
                name='email'
                id='email'
                placeholder='Email'
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for='password'>Password</Label>
              <Input
                type='password'
                name='password'
                id='password'
                placeholder='Password'
                onChange={this.onChange}
              />
            </FormGroup>
            <Button onClick={this.onLogin}>Login</Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default Login;
