import React, { Component } from 'react';
import _ from 'lodash';

import { Table, Button } from 'reactstrap';

import EditModal from '../../containers/Package/EditModal';

import CopyButton from '../../components/CopyButton';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  updateDoc,
} from 'firebase/firestore';
import { loadDB } from '../../App';

class Packages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      lastItem: null,
      editModal: null,
      pageSize: 25,
      totalCount: 0,
      orderBy: { path: 'createdAt', direction: 'desc' },
    };

    this.fetchNextPage = this.fetchNextPage.bind(this);
    this.fetchCurrentPage = this.fetchCurrentPage.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
  }

  async save(item) {
    const { db } = await loadDB();
    const ref = doc(collection(db, 'packages'), item.id);
    try {
      await updateDoc(ref, { ..._.omit(item, 'items') });
      this.fetchCurrentPage();
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  async delete(id) {
    try {
      const { db } = await loadDB();
      const ref = doc(collection(db, 'packages'), id);
      await deleteDoc(ref);
      this.fetchCurrentPage();
    } catch (error) {
      console.log('Error: ', error);
    } finally {
      this.closeModal();
    }
  }

  async componentDidMount() {
    try {
      const { db } = await loadDB();
      const ref = collection(db, 'packages');
      const { path, direction } = this.state.orderBy;
      const q = query(
        ref,
        orderBy(path, direction),
        limit(this.state.pageSize)
      );
      const docs = await getDocs(q);
      const items = [];
      docs.forEach((doc) => {
        items.push({ id: doc.id, ...doc.data() });
      });
      const lastItem =
        docs.size < this.state.pageSize
          ? null
          : docs.docs[docs.size - 1].data();
      this.setState({ items, lastItem });
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  async fetchCurrentPage() {
    try {
      const { db } = await loadDB();
      const ref = collection(db, 'packages');
      const { path, direction } = this.state.orderBy;
      const q = query(
        ref,
        orderBy(path, direction),
        limit(this.state.pageSize)
      );
      const items = [];
      const docs = await getDocs(q);
      docs.forEach((doc) => {
        items.push({ id: doc.id, ...doc.data() });
      });
      const lastItem =
        docs.size < this.state.pageSize
          ? null
          : docs.docs[docs.size - 1].data();
      this.setState({ items, lastItem });
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  async fetchNextPage() {
    if (this.state.lastItem !== null) {
      try {
        const { db } = await loadDB();
        const ref = collection(db, 'packages');
        const { path, direction } = this.state.orderBy;
        const q = query(
          ref,
          orderBy(path, direction),
          startAfter(this.state.lastItem.createdAt),
          limit(this.state.pageSize)
        );
        const docs = await getDocs(q);
        const { items } = this.state;
        docs.forEach((doc) => {
          items.push({ id: doc.id, ...doc.data() });
        });
        const lastItem =
          docs.size < this.state.pageSize
            ? null
            : docs.docs[docs.size - 1].data();
        this.setState({ items, lastItem });
      } catch (error) {
        console.log('Error: ', error);
      }
    }
  }

  async copyPackage(item) {
    try {
      const createdAt = Date.now();
      const { db } = await loadDB();
      const ref = collection(db, 'packages');
      await addDoc(ref, {
        ..._.omit(item, 'id'),
        createdAt,
        updatedAt: createdAt,
      });
      this.fetchCurrentPage();
    } catch (error) {
      console.log(error);
    }
  }

  closeModal() {
    this.setState({ editModal: null });
  }

  async openModal(e, item) {
    if (e.target.nodeName.toLowerCase() !== 'a') {
      const { selection } = item;
      const { db } = await loadDB();
      const ref = collection(db, 'talents');
      const itemsPromise = _.map(selection, async (id) => {
        const docRef = await getDoc(doc(ref, id));
        return { id: docRef.id, ...docRef.data() };
      });
      Promise.all(itemsPromise).then((items) => {
        this.setState({ editModal: { ...item, items } });
      });
    }
  }

  render() {
    const { items } = this.state;

    const renderTableRow = (item) => (
      <tr
        key={item.id}
        onClick={(e) => this.openModal(e, item)}
        style={{ cursor: 'pointer' }}
      >
        <td>{item.name}</td>
        <td>{item.book ? 'Yes' : '-'}</td>
        <td>{item.polaroids ? 'Yes' : '-'}</td>
        <td>{item.state}</td>
        <td>{item.id}</td>
        <td>
          <CopyButton
            id={item.id}
            copyText={`https://nestmodelmanagement.com/package/${item.id}`}
          />
        </td>
        <td>
          <a
            rel='noopener noreferrer nofollow'
            target='_blank'
            href={`https://nestmodelmanagement.com/package/${
              item.id
            }?preview=true`}
          >
            preview
          </a>
        </td>
        <td>
          <a
            href='/#'
            onClick={(e) => {
              e.preventDefault();
              this.copyPackage(item);
            }}
          >
            copy package
          </a>
        </td>
      </tr>
    );

    return (
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          marginTop: 24,
        }}
      >
        <div style={{ flex: 1, overflowY: 'scroll', position: 'relative' }}>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Book</th>
                <th>Polaroids</th>
                <th>State</th>
                <th>#</th>
                <th />
                <th />
              </tr>
            </thead>
            <tbody>{_.map(items, renderTableRow)}</tbody>
          </Table>
          {this.state.lastItem !== null && (
            <div style={{ margin: 34, textAlign: 'center' }}>
              <Button onClick={this.fetchNextPage}>Load more</Button>
            </div>
          )}
        </div>
        {this.state.editModal && (
          <EditModal
            save={this.save}
            delete={this.delete}
            {...this.state.editModal}
            closeModal={this.closeModal}
          />
        )}
      </div>
    );
  }
}

export default Packages;
