import _ from 'lodash';
import React, { Component } from 'react';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

import classNames from 'classnames';
import DropZone from '../../../components/DropZone';
import DatePicker from 'react-datepicker';
import { arrayMove } from 'react-sortable-hoc';
import SortableList from '../../../components/SortableList';

import { generateRandom } from '../../../utils';
import Image from '../../../components/Image';
import EmbedVideoModal from '../EmbedVideoModal';

import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';

import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  TabContent,
  TabPane,
  Spinner,
} from 'reactstrap';

class TalentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'details',
      loading: false,
      videoModalOpen: false,
      form: {
        firstName: '',
        lastName: '',
        artistName: '',
        email: '',
        phone: '',
        instagram: '',
        instagramSnippet: '',
        division: '',
        motherAgency: '',
        birthday: '',
        published: '',
        height: '',
        chest: '',
        waist: '',
        hips: '',
        suit: '',
        shoes: '',
        hair: '',
        eyes: '',
        collar: '',
        inseam: '',
        outseam: '',
        notes: '',
        street: '',
        houseNumber: '',
        entry: '',
        postCode: '',
        city: '',
        country: '',
        iban: '',
        bic: '',
        taxId: '',
        vatId: '',
        fiscalOffice: '',
        bankInfo: '',
        thumbnail: {},
        book: [],
        polaroids: [],
        sports: '',
        isBipoc: false,
        ...props.currentItem,
      },
    };

    this.closeModal = this.closeModal.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onChange = this.onChange.bind(this);
    this.save = this.save.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.clearFiles = this.clearFiles.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.delete = this.delete.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.deleteMedia = this.deleteMedia.bind(this);
  }

  onSortEnd = ({ oldIndex, newIndex, type }) => {
    this.setState(({ form }) => ({
      form: { ...form, [type]: arrayMove(form[type], oldIndex, newIndex) },
    }));
  };

  delete() {
    const resp = window.confirm('Do you really want to delete?');
    if (resp) {
      this.props.delete(this.state.form.id);
    }
  }

  onDrop(uploads, rejectedFiles, folder) {
    if (!_.isEmpty(rejectedFiles)) {
      alert(
        [
          'following files were too big, please upload only max 8 MB files',
          _.map(rejectedFiles, (file) => file.name).join(', '),
        ].join(': ')
      );
    }
    const filesPromises = _.map(uploads, (file) => {
      var img = document.createElement('img');
      const src = URL.createObjectURL(file);
      img.src = src;
      return new Promise((resolve, reject) => {
        img.onload = () => {
          resolve({
            src,
            file,
            mimeType: file.type,
            width: img.width,
            height: img.height,
          });
        };
        img.onerror = (error) => {
          reject(error);
        };
      });
    });
    Promise.all(filesPromises).then((files) => {
      const newFiles =
        folder === 'thumbnail'
          ? { thumbnail: files[0] }
          : { [folder]: [...files, ...this.state.form[folder]] };
      this.setState({
        form: {
          ...this.state.form,
          ...newFiles,
        },
      });
    });
  }

  clearFiles() {
    _.each(
      this.state.form.book,
      (file) => file.file && URL.revokeObjectURL(file.url)
    );
    _.each(
      this.state.form.polaorids,
      (file) => file.file && URL.revokeObjectURL(file.url)
    );
    this.state.form.thumbnail &&
      this.state.form.thumbnail.file &&
      URL.revokeObjectURL(this.state.form.thumbnail.url);
  }

  componentWillUnmount() {
    this.clearFiles();
  }

  async closeModal() {
    await this.setState({
      activeTab: 'details',
    });
    await this.props.closeModal();
    this.clearFiles();
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  async uploadFile(item, folder) {
    const storage = getStorage();
    if (item && item.file) {
      const type = String(item.mimeType.match(/image|video/));
      if (type) {
        const file = item.file;
        const metadata = {
          contentType: item.mimeType,
        };
        const path = `/talents/${
          this.state.form.id
        }/${folder}/${type}/${generateRandom(file.name)}`;
        try {
          const uploadRef = ref(storage, path);
          await uploadBytes(uploadRef, file, metadata);
          const downloadURL = await getDownloadURL(uploadRef);
          return _.omit({ ...item, src: downloadURL }, 'file');
        } catch (error) {
          console.log(error);
        } finally {
          URL.revokeObjectURL(file.url);
        }
      }
    }
    return item;
  }

  save() {
    this.setState({ loading: true }, () => {
      const { form } = this.state;
      Promise.all(_.map(form.book, (item) => this.uploadFile(item, 'book')))
        .then((resp) => {
          form.book = resp;
          return Promise.all(
            _.map(form.polaroids, (item) => this.uploadFile(item, 'polaroids'))
          );
        })
        .then((resp) => {
          form.polaroids = resp;
          return this.uploadFile(form.thumbnail, 'thumbnail');
        })
        .then((resp) => {
          form.thumbnail = resp;
          return this.props.save(form);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          return this.setState({ loading: false });
        });
    });
  }

  onChange(event) {
    const target = event.target;
    const name = target.name;
    const value =
      target.type === 'checkbox'
        ? target.checked
        : typeof target.value === 'string'
        ? target.value.toLowerCase()
        : target.value;

    this.setState({ form: { ...this.state.form, [name]: value } });
  }

  deleteMedia(item, folder) {
    const { form } = this.state;
    _.remove(form[folder], (formItem) => formItem.src === item.src);
    this.setState({ form }, () => {
      this.save();
    });
  }

  checkUniq(fun) {
    return (event) => {
      const { value } = event.target;
      if (this.state.form['division'] !== value) {
        fun(event);
      }
    };
  }

  render() {
    const { currentItem } = this.props;
    const { form, activeTab } = this.state;
    const openVideoModal = (gallery) =>
      this.setState({ videoModalOpen: gallery });

    return (
      currentItem && (
        <Modal
          isOpen={!!currentItem}
          toggle={this.closeModal}
          className={this.props.className}
          style={{ padding: 24, maxWidth: 1440 }}
        >
          <ModalHeader toggle={this.closeModal}>
            {currentItem.artistName ||
              `${currentItem.firstName} ${currentItem.lastName}`}
          </ModalHeader>
          <ModalBody style={{ position: 'relative' }}>
            {this.state.loading && (
              <div
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.55)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Spinner />
              </div>
            )}
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classNames({ active: activeTab === 'details' })}
                  onClick={() => {
                    this.toggle('details');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classNames({ active: activeTab === 'info' })}
                  onClick={() => {
                    this.toggle('info');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Info
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classNames({ active: activeTab === 'bio' })}
                  onClick={() => {
                    this.toggle('bio');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Bio
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classNames({ active: activeTab === 'material' })}
                  onClick={() => {
                    this.toggle('material');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Media
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent style={{ marginTop: 20 }} activeTab={activeTab}>
              <TabPane tabId='details'>
                <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='firstName'>First name</Label>
                        <Input
                          type='text'
                          name='firstName'
                          id='firstName'
                          placeholder='First name'
                          onChange={this.onChange}
                          value={form.firstName}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for='lastName'>Last name</Label>
                        <Input
                          type='text'
                          name='lastName'
                          id='lastName'
                          placeholder='Last name'
                          onChange={this.onChange}
                          value={form.lastName}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for='pronouns'>Pronouns</Label>
                        <Input
                          type='select'
                          name='pronouns'
                          id='pronouns'
                          placeholder='Pronouns'
                          value={form.pronouns}
                          onChange={this.onChange}
                        >
                          <option value=''>no pronouns</option>
                          <option value='he'>He/Him</option>
                          <option value='they'>They/Them</option>
                          <option value='she'>She/Her</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='artistName'>Artist name</Label>
                        <Input
                          type='text'
                          name='artistName'
                          id='artistName'
                          placeholder='Artist name'
                          onChange={this.onChange}
                          value={form.artistName}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for='division'>Divison</Label>
                        <Input
                          type='select'
                          name='division'
                          id='division'
                          placeholder='Division'
                          value={form.division}
                          onChange={this.onChange}
                        >
                          <option value=''>no division</option>
                          <option value='talent'>Talent</option>
                          <option value='image'>Image</option>
                          <option value='mainboard'>Mainboard</option>
                          <option value='development'>Development</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for='division'>Divison 2</Label>
                        <Input
                          type='select'
                          name='divisionAlt'
                          id='divisionAlt'
                          placeholder='Division 2'
                          value={form.divisionAlt}
                          onChange={this.checkUniq(this.onChange)}
                        >
                          <option value=''>no division</option>
                          <option value='talent'>Talent</option>
                          <option value='image'>Image</option>
                          <option value='mainboard'>Mainboard</option>
                          <option value='development'>Development</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for='motherAgency'>motherAgency</Label>
                        <Input
                          type='text'
                          name='motherAgency'
                          id='motherAgency'
                          placeholder='Mother agency'
                          onChange={this.onChange}
                          value={form.motherAgency}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup check style={{ marginBottom: 20 }}>
                        <Label check>
                          <Input
                            name='published'
                            type='checkbox'
                            onChange={this.onChange}
                            checked={form.published}
                          />{' '}
                          Active (shown on webpage)
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check style={{ marginBottom: 20 }}>
                        <Label check>
                          <Input
                            name='isBipoc'
                            type='checkbox'
                            onChange={this.onChange}
                            checked={form.isBipoc}
                          />{' '}
                          BIPOC
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='email'>Email</Label>
                        <Input
                          type='text'
                          name='email'
                          id='email'
                          placeholder='Email'
                          onChange={this.onChange}
                          value={form.email}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for='phone'>Phone</Label>
                        <Input
                          type='text'
                          name='phone'
                          id='phone'
                          placeholder='Phone'
                          onChange={this.onChange}
                          value={form.phone}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='instagram'>Instagram</Label>
                        <Input
                          type='text'
                          name='instagram'
                          id='instagram'
                          placeholder='Instagram'
                          onChange={this.onChange}
                          value={form.instagram}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for='modelsDotCom'>Models.com URL</Label>
                        <Input
                          type='text'
                          name='modelsDotCom'
                          id='modelsDotCom'
                          placeholder='Models.com URL'
                          onChange={this.onChange}
                          value={form.modelsDotCom}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for='tiktok'>TikTok</Label>
                        <Input
                          type='text'
                          name='tiktok'
                          id='tiktok'
                          placeholder='TikTok'
                          onChange={this.onChange}
                          value={form.tiktok}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for='website'>Website</Label>
                        <Input
                          type='text'
                          name='website'
                          id='website'
                          placeholder='Website'
                          onChange={this.onChange}
                          value={form.website}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='height'>Height</Label>
                        <Input
                          type='text'
                          name='height'
                          id='height'
                          placeholder='Height'
                          onChange={this.onChange}
                          value={form.height}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for='chest'>Chest</Label>
                        <Input
                          type='text'
                          name='chest'
                          id='chest'
                          placeholder='Chest'
                          onChange={this.onChange}
                          value={form.chest}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for='waist'>Waist</Label>
                        <Input
                          type='text'
                          name='waist'
                          id='waist'
                          placeholder='Waist'
                          onChange={this.onChange}
                          value={form.waist}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for='hips'>Hips</Label>
                        <Input
                          type='text'
                          name='hips'
                          id='hips'
                          placeholder='Hips'
                          onChange={this.onChange}
                          value={form.hips}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='suit'>Suit</Label>
                        <Input
                          type='text'
                          name='suit'
                          id='suit'
                          placeholder='suit'
                          onChange={this.onChange}
                          value={form.suit}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for='shoes'>Shoes</Label>
                        <Input
                          type='text'
                          name='shoes'
                          id='shoes'
                          placeholder='Shoes'
                          onChange={this.onChange}
                          value={form.shoes}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for='hair'>Hair</Label>
                        <Input
                          type='text'
                          name='hair'
                          id='hair'
                          placeholder='Hair'
                          onChange={this.onChange}
                          value={form.hair}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for='eyes'>Eyes</Label>
                        <Input
                          type='text'
                          name='eyes'
                          id='eyes'
                          placeholder='Eyes'
                          onChange={this.onChange}
                          value={form.eyes}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='collar'>Collar</Label>
                        <Input
                          type='text'
                          name='collar'
                          id='collar'
                          placeholder='Collar'
                          onChange={this.onChange}
                          value={form.collar}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for='inseam'>Inseam</Label>
                        <Input
                          type='text'
                          name='inseam'
                          id='inseam'
                          placeholder='Inseam'
                          onChange={this.onChange}
                          value={form.inseam}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for='outseam'>Outseam</Label>
                        <Input
                          type='text'
                          name='outseam'
                          id='outseam'
                          placeholder='Outseam'
                          onChange={this.onChange}
                          value={form.outseam}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for='birthday'>Birthday</Label>
                        <DatePicker
                          selected={this.state.birthday}
                          onChange={(date) =>
                            this.onChange({
                              target: {
                                name: 'birthday',
                                value: [
                                  date.getFullYear(),
                                  date.getMonth() + 1,
                                  date.getDate(),
                                ].join('-'),
                              },
                            })
                          }
                          customInput={<Input />}
                          title='Birthday'
                          name='birthday'
                          openToDate={
                            form.birthday
                              ? new Date(form.birthday)
                              : ((date) =>
                                  new Date(
                                    [
                                      date.getFullYear() - 18,
                                      date.getMonth() + 1,
                                      date.getDate(),
                                    ].join('-')
                                  ))(new Date())
                          }
                          showYearDropdown={true}
                          style={{ display: 'block' }}
                          value={form.birthday}
                          inline={false}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='sports'>Sports</Label>
                        <Input
                          type='text'
                          name='sports'
                          id='sports'
                          placeholder='Sports'
                          onChange={this.onChange}
                          value={form.sports}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='notes'>Notes</Label>
                        <Input
                          type='TextArea'
                          name='notes'
                          id='notes'
                          placeholder='Notes'
                          onChange={this.onChange}
                          value={form.notes}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </TabPane>
              <TabPane tabId='info'>
                <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='street'>Street</Label>
                        <Input
                          type='street'
                          name='street'
                          id='street'
                          placeholder='Street'
                          onChange={this.onChange}
                          value={form.street}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for='houseNumber'>House number</Label>
                        <Input
                          type='houseNumber'
                          name='houseNumber'
                          id='houseNumber'
                          placeholder='houseNumber'
                          onChange={this.onChange}
                          value={form.houseNumber}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='entry'>House entry</Label>
                        <Input
                          type='text'
                          name='entry'
                          id='entry'
                          placeholder='House entry'
                          onChange={this.onChange}
                          value={form.entry}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for='postCode'>Post code</Label>
                        <Input
                          type='text'
                          name='postCode'
                          id='postCode'
                          placeholder='postCode'
                          onChange={this.onChange}
                          value={form.postCode}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='city'>City</Label>
                        <Input
                          type='text'
                          name='city'
                          id='city'
                          placeholder='City'
                          onChange={this.onChange}
                          value={form.city}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for='country'>Country</Label>
                        <Input
                          type='text'
                          name='country'
                          id='country'
                          placeholder='Country'
                          onChange={this.onChange}
                          value={form.country}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='iban'>IBAN</Label>
                        <Input
                          type='text'
                          name='iban'
                          id='iban'
                          placeholder='IBAN'
                          onChange={this.onChange}
                          value={form.iban}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for='bic'>BIC</Label>
                        <Input
                          type='text'
                          name='bic'
                          id='bic'
                          placeholder='BIC'
                          onChange={this.onChange}
                          value={form.bic}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='taxId'>Tax id</Label>
                        <Input
                          type='text'
                          name='taxId'
                          id='taxId'
                          placeholder='Tax id'
                          onChange={this.onChange}
                          value={form.taxId}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for='vatId'>VAT id</Label>
                        <Input
                          type='text'
                          name='vatId'
                          id='vatId'
                          placeholder='VAT id'
                          onChange={this.onChange}
                          value={form.vatId}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='fiscalOffice'>Fiscal office</Label>
                        <Input
                          type='text'
                          name='fiscalOffice'
                          id='fiscalOffice'
                          placeholder='Fiscal office'
                          onChange={this.onChange}
                          value={form.fiscalOffice}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='bankInfo'>Other bank information</Label>
                        <Input
                          type='TextArea'
                          name='bankInfo'
                          id='bankInfo'
                          placeholder='Other bank info'
                          onChange={this.onChange}
                          value={form.bankInfo}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </TabPane>

              <TabPane tabId='bio'>
                <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='bioContent'>Bio text</Label>
                        <Input
                          type='textarea'
                          name='bioContent'
                          id='bioContent'
                          rows='18'
                          placeholder='Bio text'
                          onChange={this.onChange}
                          value={form.bioContent}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </TabPane>
              <TabPane tabId='material'>
                <h3>Book</h3>
                <Form
                  style={{
                    marginLeft: -5,
                    marginTop: -5,
                  }}
                >
                  <DropZone
                    onDrop={(acceptedFiles, rejectedFiles) =>
                      this.onDrop(acceptedFiles, rejectedFiles, 'book')
                    }
                    onAddVideo={() => openVideoModal('book')}
                  />
                  <SortableList
                    items={form.book}
                    folder='book'
                    deleteMethod={this.deleteMedia}
                    axis='xy'
                    helperClass='is-dragging'
                    getContainer={() =>
                      document.getElementsByClassName('modal')[0]
                    }
                    onSortEnd={({ oldIndex, newIndex }) =>
                      this.onSortEnd({ oldIndex, newIndex, type: 'book' })
                    }
                    shouldCancelStart={(e) => !e.target.draggable}
                  />
                </Form>
                <h3>Polaroids</h3>
                <Form
                  style={{
                    marginLeft: -5,
                    marginTop: -5,
                  }}
                >
                  <DropZone
                    onDrop={(acceptedFiles, rejectedFiles) =>
                      this.onDrop(acceptedFiles, rejectedFiles, 'polaroids')
                    }
                    onAddVideo={() => openVideoModal('polaroids')}
                  />
                  <SortableList
                    items={form.polaroids}
                    folder='polaroids'
                    deleteMethod={this.deleteMedia}
                    axis='xy'
                    helperClass='is-dragging'
                    onSortEnd={({ oldIndex, newIndex }) =>
                      this.onSortEnd({ oldIndex, newIndex, type: 'polaroids' })
                    }
                    shouldCancelStart={(e) => !e.target.draggable}
                  />
                </Form>
                <h3>Thumbnail</h3>
                <Form
                  style={{
                    marginLeft: -5,
                    marginTop: -5,
                    overflowX: 'scroll',
                  }}
                >
                  <DropZone
                    onDrop={(acceptedFiles, rejectedFiles) =>
                      this.onDrop(acceptedFiles, rejectedFiles, 'thumbnail')
                    }
                    accept={['image/*']}
                    multiple={false}
                  />
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    {_.get(form, 'thumbnail.src') && [
                      <div key='cover' style={{ margin: 5 }}>
                        <legend>Cover</legend>
                        <div
                          style={{
                            width: 187,
                            height: 280,
                            display: 'flex',
                            justifyContent: 'center',
                            overflow: 'hidden',
                          }}
                        >
                          <div
                            style={{
                              height: '100%',
                            }}
                          >
                            <Image
                              style={{ height: '100%' }}
                              src={_.get(form, 'thumbnail.src')}
                              type='thumb'
                            />
                          </div>
                        </div>
                      </div>,
                      <div key='thumb' style={{ margin: 5 }}>
                        <legend>Mini thumb</legend>
                        <div
                          style={{
                            width: 74,
                            height: 110,
                            display: 'flex',
                            justifyContent: 'center',
                            overflow: 'hidden',
                          }}
                        >
                          <div
                            style={{
                              height: '100%',
                            }}
                          >
                            <Image
                              style={{ height: '100%' }}
                              src={form.thumbnail.src}
                              type='mini'
                            />
                          </div>
                        </div>
                      </div>,
                    ]}
                  </div>
                </Form>
              </TabPane>
            </TabContent>
          </ModalBody>
          <ModalFooter
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              {!this.props.newRecord && (
                <Button
                  color='danger'
                  onClick={this.delete}
                  disabled={this.state.loading}
                >
                  Delete
                </Button>
              )}
            </div>
            <div>
              <Button
                color='primary'
                onClick={this.save}
                disabled={this.state.loading}
              >
                Save
              </Button>{' '}
              <Button
                color='secondary'
                onClick={this.closeModal}
                disabled={this.state.loading}
              >
                Close
              </Button>
            </div>
          </ModalFooter>
          {this.state.videoModalOpen && (
            <EmbedVideoModal
              closeVideoModal={() => this.setState({ videoModalOpen: false })}
              addVideo={(props) =>
                this.setState(
                  {
                    form: {
                      ...this.state.form,
                      [this.state.videoModalOpen]: [
                        {
                          mimeType: 'video/embed',
                          src: props.url,
                          width: props.width,
                          height: props.height,
                        },
                        ...this.state.form[this.state.videoModalOpen],
                      ],
                    },
                  },
                  () => {
                    this.setState({ videoModalOpen: false });
                  }
                )
              }
            />
          )}
        </Modal>
      )
    );
  }
}

export default TalentModal;
