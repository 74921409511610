import React, { Component } from 'react';

import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';

import Header from './containers/Header';
import Login from './containers/Login';
import News from './containers/News';
import Talents from './containers/Talents';
import Packages from './containers/Packages';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { getApp, initializeApp, getApps } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const config = require('./.firebaseconf.json');

const getNestApp = async () => {
  if (!getApps().length) {
    return await initializeApp(config);
  }
  return await getApp();
};

export async function loadDB() {
  const app = await getNestApp();
  const db = await getFirestore(app);
  return { db };
}

class App extends Component {
  constructor() {
    super();
    this.state = {
      finishedLoadingAuthState: false,
      isAuthenticated: false,
      navigation: {
        visible: false,
        active: 'News',
        loggedIn: false,
      },
      db: undefined,
    };
  }

  componentDidMount() {
    loadDB().then(async (resp) => {
      this.auth = getAuth();
      this.setState({ db: resp.db });
      onAuthStateChanged(this.auth, (user) => {
        if (user) {
          this.setState({
            isAuthenticated: true,
            finishedLoadingAuthState: true,
          });
        } else {
          this.setState({
            isAuthenticated: false,
            finishedLoadingAuthState: true,
          });
        }
      });
    });
  }

  render() {
    const { isAuthenticated, finishedLoadingAuthState, db } = this.state;

    return (
      <Container style={{ height: '100vh' }}>
        {finishedLoadingAuthState && (
          <BrowserRouter>
            <div
              style={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Header
                isAuthenticated={isAuthenticated}
                navigation={this.state.navigation}
                auth={this.auth}
              />
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  overflowY: 'scroll',
                }}
              >
                {!isAuthenticated ? (
                  <Switch>
                    <Route
                      exact
                      path='/login'
                      render={(props) => <Login {...props} auth={this.auth} />}
                    />
                    <Route path='/' render={() => <Redirect to='/login' />} />
                  </Switch>
                ) : (
                  <Switch>
                    <Route
                      exact
                      path='/'
                      render={() => <Redirect to='/news' />}
                    />
                    <Route
                      exact
                      path='/login'
                      render={() => <Redirect to='/news' />}
                    />
                    <Route
                      path='/packages'
                      render={(props) => <Packages {...props} db={db} />}
                    />
                    <Route
                      path='/talents'
                      render={(props) => (
                        <Talents {...props} db={db} storage={this.storage} />
                      )}
                    />
                    <Route
                      path='/news'
                      render={(props) => (
                        <News {...props} db={db} storage={this.storage} />
                      )}
                    />
                  </Switch>
                )}
              </div>
            </div>
          </BrowserRouter>
        )}
      </Container>
    );
  }
}

export default App;
