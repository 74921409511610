import React, { Component } from 'react';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    const {} = this.props;
    const {} = this.state;

    return(
      <div style={{width: '100%', height: 40, marginTop: 14}}>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div style={{ cursor: 'pointer' }} onClick={() => this.props.search('a')}>A</div>
          <div style={{ cursor: 'pointer' }} onClick={() => this.props.search('b')}>B</div>
          <div style={{ cursor: 'pointer' }} onClick={() => this.props.search('c')}>C</div>
          <div style={{ cursor: 'pointer' }} onClick={() => this.props.search('d')}>D</div>
          <div style={{ cursor: 'pointer' }} onClick={() => this.props.search('e')}>E</div>
          <div style={{ cursor: 'pointer' }} onClick={() => this.props.search('f')}>F</div>
          <div style={{ cursor: 'pointer' }} onClick={() => this.props.search('g')}>G</div>
          <div style={{ cursor: 'pointer' }} onClick={() => this.props.search('h')}>H</div>
          <div style={{ cursor: 'pointer' }} onClick={() => this.props.search('i')}>I</div>
          <div style={{ cursor: 'pointer' }} onClick={() => this.props.search('j')}>J</div>
          <div style={{ cursor: 'pointer' }} onClick={() => this.props.search('k')}>K</div>
          <div style={{ cursor: 'pointer' }} onClick={() => this.props.search('l')}>L</div>
          <div style={{ cursor: 'pointer' }} onClick={() => this.props.search('m')}>M</div>
          <div style={{ cursor: 'pointer' }} onClick={() => this.props.search('n')}>N</div>
          <div style={{ cursor: 'pointer' }} onClick={() => this.props.search('o')}>O</div>
          <div style={{ cursor: 'pointer' }} onClick={() => this.props.search('p')}>P</div>
          <div style={{ cursor: 'pointer' }} onClick={() => this.props.search('q')}>Q</div>
          <div style={{ cursor: 'pointer' }} onClick={() => this.props.search('r')}>R</div>
          <div style={{ cursor: 'pointer' }} onClick={() => this.props.search('s')}>S</div>
          <div style={{ cursor: 'pointer' }} onClick={() => this.props.search('t')}>T</div>
          <div style={{ cursor: 'pointer' }} onClick={() => this.props.search('u')}>U</div>
          <div style={{ cursor: 'pointer' }} onClick={() => this.props.search('v')}>V</div>
          <div style={{ cursor: 'pointer' }} onClick={() => this.props.search('w')}>W</div>
          <div style={{ cursor: 'pointer' }} onClick={() => this.props.search('x')}>X</div>
          <div style={{ cursor: 'pointer' }} onClick={() => this.props.search('y')}>Y</div>
          <div style={{ cursor: 'pointer' }} onClick={() => this.props.search('z')}>Z</div>
        </div>
      </div>
    );
  }
}

export default Footer;
