import React, { Component } from "react";
import ClipboardJS from "clipboard";

class CopyButton extends Component {
  clipboard;

  componentDidMount() {
    this.clipboard = new ClipboardJS(`#btn-${this.props.id}`);
  }
  componentWillUnmount() {
    this.clipboard.destroy();
  }

  render() {
    const copyId = `copy-${this.props.id}`;
    const btnId = `btn-${this.props.id}`;
    return [
      <input
        readOnly
        style={{ width: 1, height: 1, margin: 0, padding: 0, border: "none" }}
        key={`copy-in-${copyId}`}
        id={copyId}
        value={this.props.copyText}
      />,
      <button
        key={`copy-trig-${copyId}`}
        id={btnId}
        data-clipboard-target={`#${copyId}`}
        onClick={(e) => {e.preventDefault(); e.stopPropagation()}}
      >
        Copy link
      </button>
    ];
  }
}

export default CopyButton;
