import React, { Component } from "react";

import Image from "../Image";
import DeleteIcon from "../DeleteIcon";
import { generateRandom } from "../../utils";

class MediaItem extends Component {
  state = {
    videoOpen: null
  };
  render() {
    const {
      item,
      folder,
      style = {},
      height = 340,
      width,
      showDelete = true
    } = this.props;
    const type = String(item && item.mimeType && item.mimeType.match(/image|video/));
    let tag = "";

    switch (type) {
      case "video":
        tag = (
          <span
            style={{
              margin: 50,
            }}
          >
            {
              (item.src.match(/src="[^"]*/g)
                ? item.src.match(/src="[^"]*/g)[0]
                : ""
              )
                .replace('src="', "")
                .replace('"', "")
            }
          </span>
        );
        break;
      case "image":
        tag = <Image type="small" src={item.src} alt="img" />;
        break;
      default:
        tag = <span>Text</span>;
        break;
    }

    return (
      <div
        draggable
        key={generateRandom()}
        style={{
          ...style,
          position: "relative",
          display: "inline-block",
          verticalAlign: "top",
          height,
          width,
          margin: 5,
          background: "#e3e3e3"
        }}
      >
        {tag}
        {showDelete && (
          <div style={{ position: "absolute", top: 10, right: 10 }}>
            <DeleteIcon onClick={() => this.props.deleteMethod(item, folder)} />
          </div>
        )}
      </div>
    );
  }
}

export default MediaItem;
