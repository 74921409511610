import _ from 'lodash';
import React, { Component } from 'react';

import Image from '../../components/Image';

class SelectionFooter extends Component {
  render() {
    const { items } = this.props;

    return(
      <div style={{width: '100%', display: "flex", marginLeft: -5, overflowX: 'scroll'}}>
        {_.map(items, (item) => <div key={`selection-${item.id}`} onClick={() => this.props.removeFromSelection(item)} style={{margin: 5, cursor: 'not-allowed'}}><Image key={`package-item-${item.id}`} src={_.get(item, 'thumbnail.src')} type="mini" /></div>)}
        {!_.isEmpty(items) && (
          <div
            onClick={this.props.onCreatePackage}
            style={{width: 74, cursor: 'pointer', backgroundColor: '#e3e3e3', height: 110, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: 5}}
            >
            create pkg
          </div>
        )}
        {!_.isEmpty(items) && (
          <div
            onClick={this.props.onAddToPackage}
            style={{width: 74, cursor: 'pointer', backgroundColor: '#e3e3e3', height: 110, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: 5}}
            >
            add to pkg
          </div>
        )}
      </div>
    );
  }
}

export default SelectionFooter;
