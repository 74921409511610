import React, { Component } from 'react';
import _, { keyBy, map, omit } from 'lodash';

import { Table, Button, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import TalentModal from '../Modals/TalentModal';
import Alphabet from '../Alphabet';
import AddIcon from '../../components/AddIcon';
import Image from '../../components/Image';
import SelectionFooter from '../SelectionFooter';
import CreatePackageModal from '../CreatePackageModal';
import AddToPackageModal from '../AddToPackageModal';
import { capitalizeArtistName } from '../../utils';
import { loadDB } from '../../App';
import {
  addDoc,
  collection,
  deleteDoc,
  doc as docRef,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

class Talents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      selection: {},
      lastItem: null,
      pageSize: 6,
      currentItem: null,
      packageModalOpen: false,
      addToPackageModalOpen: false,
      orderBy: { path: 'artistName', direction: 'asc' },
      filters: {
        isBipoc: false,
        sports: '',
        ageMin: '',
        ageMax: '',
        shoesMin: '',
        shoesMax: '',
        location: '',
        division: '',
        heightMin: '',
        heightMax: '',
        some: false
      }
    };

    this.fetchNextPage = this.fetchNextPage.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.search = this.search.bind(this);
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
    this.createPackage = this.createPackage.bind(this);
    this.addToPackage = this.addToPackage.bind(this);
    this.openCreatePackageModal = this.openCreatePackageModal.bind(this);
    this.openAddToPackageModal = this.openAddToPackageModal.bind(this);
    this.fetchCurrentPage = this.fetchCurrentPage.bind(this);
  }

  async componentDidMount() {
    try {
      const { db } = await loadDB();
      const storage = getStorage();
      await this.setState({ db, storage });
      await this.fetchCurrentPage();
    } catch (error) {
      this.setState({});
      console.log('Error: ', error);
    }
  }

  openCreatePackageModal() {
    this.setState({ packageModalOpen: true });
  }

  openAddToPackageModal() {
    this.setState({ addToPackageModalOpen: true });
  }

  async createPackage(props) {
    const { db } = this.state;
    if (db) {
      try {
        const { name, book, polaroids, selection, state } = props;
        const createdAt = Date.now();
        const ref = collection(db, 'packages');
        await addDoc(ref, {
          name,
          book,
          polaroids,
          selection,
          createdAt,
          updatedAt: createdAt,
          state,
        });
        this.closeModal();
      } catch (error) {
        console.log('Error: ', error);
      } finally {
        this.props.history.push({ pathname: '/packages' });
      }
    }
  }

  async addToPackage(props) {
    const { db } = this.state;
    if (db) {
      try {
        const { selection, packageId } = props;
        const ref = docRef(collection(db, 'packages'), packageId);
        const updatedAt = Date.now();
        const doc = await getDoc(ref);
        const selected = doc.data().selection;
        await updateDoc(ref, {
          selection: _.uniq(selected.concat(selection).flat()),
          updatedAt,
        });
        this.closeModal();
      } catch (error) {
        console.log(error);
      } finally {
        this.props.history.push({ pathname: '/packages' });
      }
    }
  }

  async fetchCurrentPage() {
    const { db } = this.state;
    if (db) {
      try {
        const ref = collection(db, 'talents');
        const { path, direction } = this.state.orderBy;
        const q = query(
          ref,
          orderBy(path, direction),
          limit(this.state.pageSize)
        );
        const { items, lastItem } = await this.fetchDocs(q);
        this.setState({ items, lastItem });
      } catch (error) {
        console.log(error);
      }
    }
  }

  async fetchDocs(q) {
    const docs = await getDocs(q);
    const items = keyBy(map(docs.docs, (doc) => doc.data()), 'id');
    const lastItem =
      docs.size < this.state.pageSize ? null : docs.docs[docs.size - 1];
    return { items, lastItem };
  }

  async fetchNextPage() {
    const { db } = this.state;
    if (db) {
      if (this.state.lastItem !== null) {
        try {
          const ref = collection(db, 'talents');
          const { path, direction } = this.state.orderBy;
          const q = query(
            ref,
            orderBy(path, direction),
            startAfter(this.state.lastItem),
            limit(this.state.pageSize)
          );
          const { items, lastItem } = await this.fetchDocs(q);
          this.setState({ items: { ...this.state.items, ...items }, lastItem });
        } catch (error) {
          console.log('Error: ', error);
        }
      }
    }
  }

  async search(searchString) {
    const { db } = this.state;
    if (db) {
      const ref = collection(db, 'talents');
      console.log('SEARCH STRING: ', searchString);
      const q = query(
        ref,
        where('artistName', '>=', searchString),
        where(
          'artistName',
          '<=',
          String.fromCharCode(
            searchString.charCodeAt(searchString.length - 1) + 1
          )
        ),
        orderBy('artistName')
      );
      const { items } = await this.fetchDocs(q);
      this.setState({
        items: items,
        lastItem: null,
      });
    }
  }

  async delete(id) {
    const { db } = this.state;
    if (db && id) {
      try {
        const ref = docRef(collection(db, 'talents'), id);
        await deleteDoc(ref);
        const selection = this.state.selection;
        const items = this.state.items;
        delete selection[id];
        delete items[id];
        await this.setState({ selection, items: omit(items, id) });
      } catch (error) {
        console.log('Error: ', error);
      } finally {
        this.closeModal();
      }
    }
  }

  async save(form) {
    const { db } = this.state;
    if (db) {
      form.updatedAt = Date.now();
      const ref = collection(db, 'talents');
      const findPermalink = async (permalink) => {
        const q = query(ref, where('permalink', '==', permalink));
        const snap = await getDocs(q);
        if (snap.empty) {
          return permalink;
        } else {
          const counter = (Number(permalink.split('-').reverse()[0]) || 0) + 1;
          return findPermalink(`${permalink}-${counter}`);
        }
      };
      const updateRecord = async () => {
        try {
          const ref = docRef(collection(db, 'talents'), form.id);
          await setDoc(ref, form, { merge: true });
          const doc = (await getDoc(ref)).data();
          this.setState((oldState) => ({
            items: {
              ...oldState.items,
              [doc.id]: { id: doc.id, ...doc },
            },
          }));
        } catch (error) {
          console.log(error);
        }
      };
      if (!form.permalink) {
        form.createdAt = form.updatedAt;
        let permalink = _.kebabCase(
          form.artistName || `${form.firstName} ${form.lastName}`
        );
        return findPermalink(permalink).then((permalink) => {
          form.permalink = permalink;
          return updateRecord();
        });
      } else {
        return updateRecord();
      }
    }
  }

  async openModal(item = {}) {
    const { db } = this.state;
    if (db) {
      const newRecord = !item.id;
      if (newRecord) {
        const ref = docRef(collection(db, 'talents'));
        item.id = ref.id;
      }
      this.setState({ currentItem: item, newRecord });
    }
  }

  async closeModal() {
    await this.setState({
      currentItem: null,
      newRecord: null,
      packageModalOpen: false,
      addToPackageModalOpen: false,
    });
  }

  closeSearch() {
    this.fetchCurrentPage();
  }

  onFilterChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      filters: { ...this.state.filters, [name]: value },
    });
  }

  toggleFilters = (e) => {
    e.preventDefault();
    const filters = document.querySelectorAll('.filters');
    filters.forEach(filter => {
      if (filter.style.display === "none") {
        filter.style.display = "flex";
      } else {
        filter.style.display = "none";
      }
    });
  }

  applyFilters = async () => {
    const { db, filters } = this.state;
    if (db) {
      try {
        const ref = collection(db, 'talents');
        const { path, direction } = this.state.orderBy;
        let items = [];
        const q = query(ref, orderBy(path, direction));
        const result = await this.fetchDocs(q);
        const allItems = result.items ? Object.values(result.items) : [];
        items = allItems;

        if (filters.ageMin || filters.ageMax) {
          // Talent does not have an age, it has a birthday which is a string like "1995-12-19"
          // So we need to calculate the age based on the birthday
          const ageMin = filters.ageMin || 0;
          const ageMax = filters.ageMax || 100;
          const today = new Date();
          const minDate = new Date(today.getFullYear() - ageMax, today.getMonth(), today.getDate());
          const maxDate = new Date(today.getFullYear() - ageMin, today.getMonth(), today.getDate());
          // filter items client-side
          items = items.filter(item => {
            const birthday = new Date(item.birthday);
            return birthday >= minDate && birthday <= maxDate;
          });
        }

        if (filters.shoesMin || filters.shoesMax) {
          // Assuming shoesMin and shoesMax are already defined and converted to numbers
          const shoesMin = parseInt(filters.shoesMin || 0, 10);
          const shoesMax = parseInt(filters.shoesMax || 100, 10);
          // filter items client-side
          items = items.filter(item => {
            const shoesValue = parseInt(item.shoes, 10);
            return shoesValue >= shoesMin && shoesValue <= shoesMax;
          });
        }

        if (filters.heightMin || filters.heightMax) {
          const heightMin = parseInt(filters.heightMin || 0, 10);
          const heightMax = parseInt(filters.heightMax || 250, 10);
          // filter items client-side
          items = items.filter(item => {
            const heightValue = parseInt(item.height, 10);
            return heightValue >= heightMin && heightValue <= heightMax;
          });
        }

        if (filters.location) {
          // filter items client-side
          // case insensitive
          const location = filters.location.toLowerCase();
          items = items.filter(item => item.city.toLowerCase().includes(location) || item.postcode === filters.location || item.country.toLowerCase().includes(location));
        }

        if (filters.division) {
          // filter items client-side
          items = items.filter(item => item.division === filters.division);
        }

        if (filters.isBipoc) {
          // filter items client-side
          if (filters.isBipoc === 'true') {
            items = items.filter(item => !item.isBipoc);
          } else if (filters.isBipoc === 'false') {
            items = items.filter(item => item.isBipoc);
          }
        }

        if (filters.sports) {
          // filter items client-side
          items = items.filter(item => {
            item.sports && item.sports.includes(filters.sports)
          });
        }

        if (filters.some) {
          if (filters.some == 'false') {
            items = items.filter(item => !item.instagram && !item.tiktok);
          } else if (filters.some == 'true') {
            items = items.filter(item => item.instagram || item.tiktok);
          }
        }
        this.setState({
          items,
          lastItem: null
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  render() {
    const { items } = this.state;

    const addToSelection = (e, item) => {
      e.preventDefault();
      e.stopPropagation();
      this.setState({
        selection: { ...this.state.selection, [item.id]: item },
      });
    };

    const removeFromSelection = (props) => {
      const { id } = props;
      this.setState({
        selection: _.omit(this.state.selection, id),
      });
    };

    const renderTableRow = (item) => (
      <tr
        key={item.id}
        onClick={() => this.openModal(item)}
        style={{ cursor: 'pointer' }}
      >
        <td>
          <Image src={_.get(item, 'thumbnail.src', '')} type='mini' />
        </td>
        <td>{capitalizeArtistName(item.artistName)}</td>
        <td>
          {[
            `H ${item.height || '-'}`,
            `Suit ${item.suit || '-'}`,
            `Shoes ${item.shoes || '-'}`,
          ].join(', ')}
        </td>
        <td>{item.permalink}</td>
        <td>{item.id}</td>
        <td>
          <a
            rel='noopener noreferrer nofollow'
            target='_blank'
            href={`https://nestmodelmanagement.com/${item.permalink}`}
          >
            view
          </a>
        </td>
        {item.thumbnail ? (
          <td onClick={(e) => addToSelection(e, item)}>Add to package</td>
        ) : (
          <td />
        )}
      </tr>
    );

    return this.state.db ? (
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          marginTop: 24,
        }}
      >
        <div>
          <h3 style={{ textAlign: 'left' }}>Filters <a style={{ fontSize: "11px"}} href="#" onClick={this.toggleFilters}>Toggle</a></h3>
          <Row className="g-1 filters" style={{ display: "none" }}>
            <Col>
              <FormGroup>
                <Label for='ageMin'>Age</Label>
                <Input
                  type='number'
                  name='ageMin'
                  id='ageMin'
                  placeholder='Min'
                  onChange={this.onFilterChange}
                  value={this.state.filters.ageMin}
                />
                <Input
                  type='number'
                  name='ageMax'
                  id='ageMin'
                  placeholder='Max'
                  onChange={this.onFilterChange}
                  value={this.state.filters.ageMax}
                  style={{ marginTop: 10 }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for='shoesMin'>Shoes</Label>
                <Input
                  type='number'
                  name='shoesMin'
                  id='shoesMin'
                  placeholder='Min'
                  onChange={this.onFilterChange}
                  value={this.state.filters.shoesMin}
                />
                <Input
                  type='number'
                  name='shoesMax'
                  id='shoesMax'
                  placeholder='Max'
                  onChange={this.onFilterChange}
                  value={this.state.filters.shoesMax}
                  style={{ marginTop: 10 }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for='heightMin'>Height</Label>
                <Input
                  type='number'
                  name='heightMin'
                  id='heightMin'
                  placeholder='Min'
                  onChange={this.onFilterChange}
                  value={this.state.filters.heightMin}
                />
                <Input
                  type='number'
                  name='heightMax'
                  id='heightMax'
                  placeholder='Max'
                  onChange={this.onFilterChange}
                  value={this.state.filters.heightMax}
                  style={{ marginTop: 10 }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for='location'>Location</Label>
                <Input
                  type='text'
                  name='location'
                  id='location'
                  placeholder='City / postcode'
                  onChange={this.onFilterChange}
                  value={this.state.filters.location}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup check style={{ marginBottom: 20 }}>
                <Label for='division'>Division</Label>
                <Input
                  type='select'
                  name='division'
                  id='division'
                  placeholder='Division'
                  onChange={this.onFilterChange}
                  value={this.state.filters.division}
                >
                  <option>No division</option>
                  <option value='talent'>Talent</option>
                  <option value='image'>Image</option>
                  <option value='mainboard'>Mainboard</option>
                  <option value='development'>Development</option>
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup check style={{ marginBottom: 20 }}>
                <Label for='isBipoc'>BIPOC</Label>
                <Input
                  type='select'
                  name='isBipoc'
                  id='isBipoc'
                  placeholder='BIPOC'
                  onChange={this.onFilterChange}
                >
                  <option>-</option>
                  <option value='true'>No</option>
                  <option value='false'>Yes</option>
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for='sports'>Sports</Label>
                <Input
                  type='textfield'
                  name='sports'
                  id='sports'
                  placeholder=''
                  onChange={this.onFilterChange}
                  value={this.state.filters.sports}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup check style={{ marginBottom: 20 }}>
                <Label for='bipoc'>SoMe?</Label>
                <Input
                  type='select'
                  name='some'
                  id='some'
                  onChange={this.onFilterChange}
                >
                  <option>-</option>
                  <option value='false'>No</option>
                  <option value='true'>Yes</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row className="filters" style={{ display: "none" }}>
            <Col>
              <Button onClick={this.applyFilters}>Apply filters</Button>
            </Col>
          </Row>
        </div>

        <div style={{ flex: 1, overflowY: 'scroll', position: 'relative' }}>
          <Table>
            <thead>
              <tr>
                <th>/</th>
                <th>Artist Name</th>
                <th>Measurements</th>
                <th>Permalink</th>
                <th>#</th>
                <th />
                <th />
              </tr>
            </thead>
            <tbody>{_.map(_.values(items), renderTableRow)}</tbody>
          </Table>
          {this.state.lastItem !== null && (
            <div style={{ margin: 34, textAlign: 'center' }}>
              <Button onClick={this.fetchNextPage}>Load more</Button>
            </div>
          )}
        </div>
        <div
          style={{
            boxShadow: '0px -1px 0px 0px rgba(0,0,0,0.15)',
            position: 'relative',
          }}
        >
          <div
            style={{ position: 'absolute', top: -120, right: 20, zIndex: 1 }}
          >
            <AddIcon onClick={() => this.openModal()} />
          </div>
          <Alphabet search={this.search} />
          <SelectionFooter
            items={_.values(this.state.selection)}
            onCreatePackage={this.openCreatePackageModal}
            onAddToPackage={this.openAddToPackageModal}
            removeFromSelection={removeFromSelection}
          />
        </div>
        {this.state.currentItem && (
          <TalentModal
            storage={this.state.storage}
            currentItem={this.state.currentItem}
            newRecord={this.state.newRecord}
            closeModal={this.closeModal}
            save={this.save}
            delete={this.delete}
          />
        )}
        {this.state.packageModalOpen && (
          <CreatePackageModal
            selection={_.keys(this.state.selection)}
            closeModal={this.closeModal}
            createPackage={this.createPackage}
          />
        )}
        {this.state.addToPackageModalOpen && (
          <AddToPackageModal
            selection={_.keys(this.state.selection)}
            closeModal={this.closeModal}
            addToPackage={this.addToPackage}
          />
        )}
      </div>
    ) : null;
  }
}

export default Talents;
